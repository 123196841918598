// @import packages
import React, { Component } from 'react';
import { Redirect, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { TweenMax } from 'gsap/TweenMax';
import ReactToPrint from 'react-to-print';
// @import components
import Loader from 'js/models/loader';
// @import constants
import { resultsdashboard_endpoint } from '../../constants/endpoints';
// @import styles
import '../../../css/containers/resultaten.scss';
// @import actions
import { sendErrorToHOC } from '../../../actions/actions';
import { ReactComponent as FilterIcon } from '../../../images/svg/filter.svg';
import { ReactComponent as PrintIcon } from '../../../images/svg/printer.svg';
// @import utils
import { axiosPost } from '../../utils/axiosCall';

export class Management extends Component {
	state = {
		redirect: false,
		isLoading: true,
		customDropdown: false,
		selectedGroup: null,
		selectedStudent: null,
		selectedAge: null,
		excludableStudents: []
	};

	componentWillMount() {
		if (this.props.location.pathname === '/') {
			this.setState({
				redirect: true
			});
		}
	}

	componentDidMount() {
		if (this.props.location.state?.excludableStudents.length > 0) {
			this.setState(
				{
					excludableStudents: this.props.location.state?.excludableStudents
				},
				() => {
					this.handleExculdeStudent();
				}
			);
		} else {
			this.getResults();
		}
	}

	getResults = (age, groupId, studentId, includedStudents) => {
		const dataForm = new FormData();
		let topicName = this.props.match.params.topicname;

		this.setState({
			topicName,
			isLoading: true
		});

		switch (topicName) {
			case 'taal':
				dataForm.append('topicId', '1');
				break;
			case 'rekenen':
				dataForm.append('topicId', '2');
				break;
			case 'motoriek':
				dataForm.append('topicId', '3');
				break;
			case 'grote-motoriek':
				dataForm.append('topicId', '4');
				break;
			default:
				dataForm.append('topicId', '1');
		}

		dataForm.append(
			'age',
			age
				? age === '-1'
					? ''
					: age
				: this.state.selectedAge
				? this.state.selectedAge.ageValue
				: ''
		);
		dataForm.append(
			'groupId',
			groupId
				? groupId === '-1'
					? ''
					: groupId
				: this.state.selectedGroup
				? this.state.selectedGroup.groupId
				: ''
		);
		dataForm.append(
			'studentId',
			studentId
				? studentId === '-1'
					? ''
					: studentId
				: this.state.selectedStudent
				? this.state.selectedStudent.studentId
				: ''
		);

		if (includedStudents) {
			dataForm.append('includedStudents', includedStudents.join());
		}
		axiosPost(
			resultsdashboard_endpoint,
			dataForm,
			this.props.token,
			axiosResult => {
				if (axiosResult.status === 'success') {
					const { ages, groups, students } = axiosResult.data.filter;
					const selectedAge = ages && ages.find(x => x.selected);
					const selectedGroup = groups && groups.find(x => x.selected);
					const selectedStudent = students && students.find(x => x.selected);
					this.setState({
						isLoading: false,
						results: axiosResult.data.results,
						dropdownFilters: axiosResult.data.filter,
						excludableStudents: axiosResult.data.excludableStudents,
						selectedAge: selectedAge ? selectedAge : null,
						selectedGroup: selectedGroup ? selectedGroup : null,
						selectedStudent: selectedStudent ? selectedStudent : null
					});
				}
			}
		);
	};

	handleAgeSelect = event => {
		const age = event.target.value;
		this.getResults(age, null, '-1');
	};

	handleGroupSelect = event => {
		const groupId = event.target.value;
		this.getResults(null, groupId, '-1');
	};

	handleStudentSelect = event => {
		const studentId = event.target.value;
		this.getResults(null, null, studentId);
	};

	toggleExcludableStudents = id => {
		const updatedExcludableStudents = [...this.state.excludableStudents].map(
			item =>
				item.studentId === id
					? Object.assign(item, {
							studentChecked: item.studentChecked === '1' ? '0' : '1'
					  })
					: item
		);
		this.setState({ excludableStudents: updatedExcludableStudents }, () => {
			this.handleExculdeStudent();
		});
	};

	handleExculdeStudent = () => {
		const includedStudents = this.state.excludableStudents.filter(
			item => item.studentChecked === '1'
		);
		let includedStudentArray = [];
		includedStudents.map(item => includedStudentArray.push(item.studentId));
		this.getResults(null, null, null, includedStudentArray);

		this.setState(
			{
				customDropdown: false
			},
			() => {
				TweenMax.to('.dropdownResults', 0.3, {
					height: '0px'
				});
				TweenMax.to('.dropdownTitle svg', 0.3, {
					fill: '#333'
				});
			}
		);
	};

	render() {
		const { redirect } = this.state;
		if (redirect) {
			return <Redirect to={this.props.initialData.defaultUrl} />;
		}
		return (
			<div className="mainContainer">
				<div className="resultatenContainer">
					<div className="">
						<ul className="tabsStyle">
							<li className="taal_tab">
								<NavLink
									to={{
										pathname: '/resultaten/taal',
										state: {
											excludableStudents: this.state.excludableStudents
										}
									}}
									activeClassName="active"
								>
									Taal
								</NavLink>
							</li>
							<li className="rekenen_tab">
								<NavLink
									to={{
										pathname: '/resultaten/rekenen',
										state: {
											excludableStudents: this.state.excludableStudents
										}
									}}
									activeClassName="active"
								>
									Rekenen
								</NavLink>
							</li>
							<li className="motoriek_tab">
								<NavLink
									to={{
										pathname: '/resultaten/motoriek',
										state: {
											excludableStudents: this.state.excludableStudents
										}
									}}
									activeClassName="active"
								>
									Motoriek
								</NavLink>
							</li>
							<li className="grote-motoriek_tab">
								<NavLink
									to={{
										pathname: '/resultaten/grote-motoriek',
										state: {
											excludableStudents: this.state.excludableStudents
										}
									}}
									activeClassName="active"
								>
									Grote Motoriek
								</NavLink>
							</li>
						</ul>
					</div>

					<div
						className={`tabBorderStyle ${
							this.state.topicName
								? this.state.topicName
										.replace(/ /g, '-')
										.toLowerCase()
										.concat('_wrapper')
								: null
						}`}
					>
						<section className="pageContainer">
							<div
								className={`groupResultsWrapper ${
									this.state.isLoading ? 'flexCenter' : ''
								}`}
							>
								{this.state.isLoading ? (
									<Loader />
								) : (
									<>
										<div className={'dropdownWrapper'}>
											{this.state.dropdownFilters && (
												<div>
													{this.state.dropdownFilters.ages && (
														<select
															value={
																this.state.selectedAge
																	? this.state.selectedAge.ageValue
																	: '-1'
															}
															onChange={this.handleAgeSelect}
														>
															<option value="-1">Alle leeftijden</option>
															{this.state.dropdownFilters.ages.map(
																(item, i) => {
																	return (
																		<option key={i} value={item.ageValue}>
																			{item.ageTitle}
																		</option>
																	);
																}
															)}
														</select>
													)}
													{this.state.dropdownFilters.groups && (
														<select
															value={
																this.state.selectedGroup
																	? this.state.selectedGroup.groupId
																	: '-1'
															}
															onChange={this.handleGroupSelect}
														>
															<option value="-1">Alle groepen</option>
															{this.state.dropdownFilters.groups.map(
																(item, i) => {
																	return (
																		<option key={i} value={item.groupId}>
																			{item.groupName}
																		</option>
																	);
																}
															)}
														</select>
													)}
													{this.state.dropdownFilters.students && (
														<select
															value={
																this.state.selectedStudent
																	? this.state.selectedStudent.studentId
																	: '-1'
															}
															onChange={this.handleStudentSelect}
														>
															<option value="-1">Alle leerlingen</option>
															{this.state.dropdownFilters.students.map(
																(item, i) => {
																	return (
																		<option key={i} value={item.studentId}>
																			{item.studentDisplayname}
																		</option>
																	);
																}
															)}
														</select>
													)}
												</div>
											)}

											<div className={'flexEnd'}>
												{this.state.excludableStudents &&
												this.state.excludableStudents.length ? (
													<div className="customDropdown">
														<div
															className="dropdownTitle"
															onClick={() => {
																this.setState(
																	{
																		customDropdown: !this.state.customDropdown
																	},
																	() => {
																		TweenMax.to('.dropdownResults', 0.3, {
																			height: this.state.customDropdown
																				? '216px'
																				: '0px'
																		});
																		TweenMax.to('.dropdownTitle svg', 0.3, {
																			fill: this.state.customDropdown
																				? '#48b848'
																				: '#333'
																		});
																	}
																);
															}}
														>
															<span> Filter leerlingen</span>
															<FilterIcon />
														</div>
														<div className="dropdownResults">
															{this.state.excludableStudents.map(item => (
																<div className="student" key={item.studentId}>
																	<input
																		id={`checkbox-${item.studentId}`}
																		type="checkbox"
																		defaultChecked={
																			item.studentChecked === '1' ? true : false
																		}
																		onChange={() => {
																			this.toggleExcludableStudents(
																				item.studentId
																			);
																		}}
																	/>
																	<label htmlFor={`checkbox-${item.studentId}`}>
																		{item.studentDisplayname}
																	</label>
																</div>
															))}
														</div>
													</div>
												) : null}
												{this.state.dropdownFilters && (
													<ReactToPrint
														trigger={() => (
															<button type="button" className="printButton">
																<PrintIcon />
															</button>
														)}
														pageStyle={
															'@media print{@page{size: landscape; margin: 6mm 10mm 9mm 10mm;} body{margin:0;} .pageContainer{background: white !important;} .printButton{display: none;}}'
														}
														content={() => this.componentRef}
													/>
												)}
											</div>
										</div>

										{this.state.results && (
											<div ref={el => (this.componentRef = el)}>
												<h1 className={'headingStyle'}>
													{this.state.results.pagetitle}
												</h1>
												<div className="resultsTable">
													<div className="titleRow">
														{/*
														<div className="column">
															{this.state.results.columns.lesson.periodName}
														</div>
														*/}
														<div className="column">
															{this.state.results.columns.lesson.lessonName}
														</div>

														{this.state.results.columns.scoreCount && (
															<div className="column alignCenter">
																{this.state.results.columns.scoreCount}
															</div>
														)}

														<div className="column alignCenter">
															{this.state.results.columns.averageScore}
														</div>

														{this.state.results.columns.groupAverageYear && (
															<div className="column alignCenter">
																{this.state.results.columns.groupAverageYear}
															</div>
														)}

														{this.state.results.columns.groupAverageScore && (
															<div className="column alignCenter">
																{this.state.results.columns.groupAverageScore}
															</div>
														)}

														{this.state.results.columns.averageOverallScore && (
															<div className="column alignCenter">
																{this.state.results.columns.averageOverallScore}
															</div>
														)}

														{this.state.results.columns.average34Score && (
															<div className="column alignCenter">
																{this.state.results.columns.average34Score}
															</div>
														)}

														{this.state.results.columns.average45Score && (
															<div className="column alignCenter">
																{this.state.results.columns.average45Score}
															</div>
														)}

														{this.state.results.columns.average56Score && (
															<div className="column alignCenter">
																{this.state.results.columns.average56Score}
															</div>
														)}

														{this.state.results.columns.average67Score && (
															<div className="column alignCenter">
																{this.state.results.columns.average67Score}
															</div>
														)}
													</div>

													{this.state.results.rows.map((item, i) => {
														return (
															<div className="resultRow" key={i}>
																{/*
																<div className="column">
																	{item.lesson.periodName} (
																	{item.lesson.seasonName})
																</div>
																*/}

																<div className="column">
																	{item.lesson.lessonName}
																</div>

																{this.state.results.columns.scoreCount && (
																	<div className="column alignCenter">
																		{item.scoreCount}
																	</div>
																)}

																<div className={`column alignCenter ${item.averageScoreMarked}`} >
																	{item.averageScore}
																</div>

																{this.state.results.columns
																	.groupAverageYear && (
																	<div className="column alignCenter">
																		{item.groupAverageYear}
																	</div>
																)}

																{this.state.results.columns
																	.groupAverageScore && (
																	<div className="column alignCenter">
																		{item.groupAverageScore}
																	</div>
																)}

																{this.state.results.columns
																	.averageOverallScore && (
																	<div className="column alignCenter">
																		{item.averageOverallScore}
																	</div>
																)}

																{this.state.results.columns.average34Score && (
																	<div className="column alignCenter">
																		{item.average34Score}
																	</div>
																)}

																{this.state.results.columns.average45Score && (
																	<div className="column alignCenter">
																		{item.average45Score}
																	</div>
																)}

																{this.state.results.columns.average56Score && (
																	<div className="column alignCenter">
																		{item.average56Score}
																	</div>
																)}

																{this.state.results.columns.average67Score && (
																	<div className="column alignCenter">
																		{item.average67Score}
																	</div>
																)}
															</div>
														);
													})}

													<div className="resultRow footerRow">
														<div
															className="column"
															style={{ borderColor: 'gray' }}
														>
															Gemiddelde
														</div>
														<div
															className="column"
															style={{ borderColor: 'gray' }}
														></div>
														{this.state.results.columns.scoreCount && (
															<div className="column"></div>
														)}
														<div
															className={`column alignCenter ${
																this.state.results.footer.averageScoreAverageMarked
															}`}
														>
															{this.state.results.footer.averageScoreAverage}
														</div>
														{this.state.results.columns.groupAverageYear && (
															<div className="column alignCenter">
																{this.state.results.footer.groupAverageYear}
															</div>
														)}
														{this.state.results.columns.groupAverageScore && (
															<div className="column alignCenter">
																{this.state.results.footer.groupAverageScore}
															</div>
														)}
														{this.state.results.columns.averageOverallScore && (
															<div className="column alignCenter">
																{
																	this.state.results.footer
																		.averageOverallScoreAverage
																}
															</div>
														)}
														{this.state.results.columns.average34Score && (
															<div className="column alignCenter">
																{
																	this.state.results.footer
																		.average34ScoreAverage
																}
															</div>
														)}
														{this.state.results.columns.average45Score && (
															<div className="column alignCenter">
																{
																	this.state.results.footer
																		.average45ScoreAverage
																}
															</div>
														)}
														{this.state.results.columns.average56Score && (
															<div className="column alignCenter">
																{
																	this.state.results.footer
																		.average56ScoreAverage
																}
															</div>
														)}
														{this.state.results.columns.average67Score && (
															<div className="column alignCenter">
																{
																	this.state.results.footer
																		.average67ScoreAverage
																}
															</div>
														)}
													</div>
												</div>
											</div>
										)}
									</>
								)}
							</div>
						</section>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	initialData: state.initialData,
	token: state.initialData.token
});

export default withRouter(
	connect(mapStateToProps, { sendErrorToHOC })(Management)
);
